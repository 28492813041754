import CRMauth from "@/services/CRMinitAuth";

const list = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}reserved_exams/`, {
    params: query
  });

const loadResult = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/fetch_result/`);

const sendResult = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/send_absent_message/`);

const patch = (ID, data) =>
  CRMauth.patch(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/`, data);

const loadResults = (params) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/fetch_results_bulk/`, null, { params: params.query });

const startMonitoring = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/start_monitor/`);

const stopMonitoring = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/stop_monitor/`);

const monitorStatus = (ID) =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}reserved_exams/monitor_status/`);

const markSeen = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/mark_seen/`);

const setResult = (ID, data) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/set_result/`, data);

const changeColor = (candidateIds, colorID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/change_color/`, { ids: candidateIds, color: colorID });

const sendResultEmail = (ID) =>
  CRMauth.post(`${process.env.VUE_APP_CRM_API}reserved_exams/${ID}/resend_results_email/`);

export default {
  setResult,
  list,
  loadResult,
  markSeen,
  monitorStatus,
  startMonitoring,
  stopMonitoring,
  loadResults,
  patch,
  changeColor,
  sendResult,
  sendResultEmail
};
