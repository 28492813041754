<template lang="pug">
  app-checkbox(
    :id="itemID"
    :inputValue="value"
    :value="value"
    :disabled="disabled"
    success
    @change="courseDayResultsEmail"
  )
</template>

<script>
import appCheckbox from '@/components/global/Checkbox.vue'
import errorsMixin from '@/mixins/errors.mixin'
import ResultsService from '@/app/admin/modules/results/core/results-service'

export default {
  mixins: [errorsMixin],

  props: {
    itemID: Number,
    value: Boolean,
    disabled: Boolean
  },

  data: () => ({
    examResultsService: new ResultsService()
  }),

  methods: {
    async courseDayResultsEmail(value) {
      try {
        await this.examResultsService.updateSendResults(this.itemID, value);
        this.$notify({type: 'success', text: 'Updated'});
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  },

  components: {
    appCheckbox
  }
}
</script>
