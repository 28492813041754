<template lang="pug">
  .checkbox(:class="classObject")
    .checkbox__wrapper
      input(
        :id="id"
        :disabled="disabled"
        :checked="isChecked"
        @change="update"
        type="checkbox"
      )
      .checkbox__checked
        i(class="material-icons") checked
    label(v-if="label" :for="id") {{ label }}
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    inputValue: {
      type: null
    },
    value: {
      type: [ String, Number, Boolean ]
    },
    disabled: Boolean,
    id: {
      required: true
    },
    label: String,
    success: Boolean,
    rounded: Boolean,
    v2: Boolean
  },

  computed: {
    classObject: function() {
      return {
        'checkbox--success': this.success,
        'checkbox--rounded': this.rounded,
        'checkbox--v2': this.v2
      }
    },

    isChecked() {
      if (this.inputValue instanceof Array) return this.inputValue.includes(this.value)
      if (typeof this.inputValue === 'boolean') return this.inputValue
      return this.inputValue === this.value
    },

    val: {
      get: function () {
        return this.inputValue
      },

      set: function (val) {
        this.$emit('change',)
      }
    }
  },

  methods: {
    update(event) {
      let isChecked = event.target.checked
      if (this.inputValue instanceof Array) {
        let newValue = [ ...this.inputValue ]
        if (isChecked) newValue.push(this.value)
        else newValue.splice(newValue.indexOf(this.value), 1)
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? true : false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &__wrapper {
    position: relative;
    margin-bottom: 0;
    margin-left: 0;
    height: 18px;
    color: #000000;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    font-weight: 700;
    font-size: 14px;
  }

  label {
    margin-left: 8px;
    cursor: pointer;
  }

  input {
    position: relative;
    margin: 0;
    min-width: 18px;
    width: 18px;
    height: 18px;
    outline: none;
    background-color: #FFFFFF;
    cursor: pointer;

    -webkit-appearance: none;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      border: 2px solid #DFE7EB;
      border-radius: 2px;
      content: "";
      transform: translateX(-50%) translateY(-50%);
    }

    &:checked:after {
      border-color: $primary-color;
      border-radius: 2px;
      background-color: $primary-color;
      box-shadow: 0 0 4px 0 rgba($primary-color, 0.5);
    }

    &:checked ~ .checkbox__checked {
      display: flex;
      cursor: pointer;
    }
  }

  &__checked {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    text-align: center;
    pointer-events: none;

    i {
      display: inline-block;
      width: 12px;
      color: #FFFFFF;
      text-align: center;
      font-size: 12px;
      user-select: none;
    }
  }

  &--v2 {
    input {
      &::after {
        border-width: 1px;
        border-color: $new-primary;
      }
      &:checked:after {
        background-color: $new-primary;
        box-shadow: 0 0 4px 0 rgba($new-primary, 0.5);
      }
    }
  }

  &-- {
    &success {
      input:checked:after {
        border-color: $success-color;
        background-color: $success-color;
        box-shadow: 0 0 4px 0 rgba($success-color, 0.5);
      }
    }

    &rounded {
      .checkbox__wrapper {
        height: 28px;
      }

      input {
        width: 28px;
        height: 28px;

        &::after {
          width: 28px;
          height: 28px;
          border-width: 2px;
          border-radius: 50%;
        }

        &:checked::after {
          border-radius: 50%;
        }
      }

      .checkbox__checked {
        width: 28px;
        height: 28px;

        .material-icons {
          width: 16px;
          font-size: 16px;
        }
      }
    }
  }
}

</style>
