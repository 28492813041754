import api from '@/services/api/api-reservedExams';
import ResultModel from './models/resultModel';

export default class ResultsService {
  async list(query) {
    try {
      let res = await api.list(query);
      if (!res.results) throw new Error('Something wrong and results not loading. Please, reload page or try later.');
      return {
        results: res.results.map(item => new ResultModel(item)),
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous,
        },
        statistic: res.statistic
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async loadResults(query) {
    try {
      return await api.loadResults(query);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async loadResult(ID) {
    try {
      await api.loadResult(ID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendResult(ID) {
    try {
      return await api.sendResult(ID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveResults(ID, data) {
    try {
      return await api.setResult(ID, data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendResultEmail(ID) {
    try {
      return await api.sendResultEmail(ID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async markSeen(ID) {
    try {
      return await api.markSeen(ID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async startMonitoring() {
    try {
      return await api.startMonitoring();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async stopMonitoring() {
    try {
      return await api.stopMonitoring();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async monitoringStatus() {
    try {
      return await api.monitorStatus();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateIgnoreResults(ID, val) {
    try {
      return await api.patch(ID, {
        ignore_results: val
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateSendResults(ID, val) {
    try {
      return await api.patch(ID, {send_result_emails: val});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveNote(ID, data) {
    try {
      return await api.patch(ID, {notes: data});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveColor(ids, colorID) {
    try {
      return await api.changeColor(ids, colorID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateWillCome(ID, data) {
    try {
      return await api.patch(ID, {will_come_to_course: data});
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
